$(function() {
  if (screen && screen.width < 480) {
    $(".jcarousel-mobile").wrap("<div class='jcarousel-wrapper jcarousel-wrapper-mobile'></div>").wrap("<div class='jcarousel'></div>");
    $(".jcarousel-wrapper-mobile").append("<a href='#' class='jcarousel-control-prev'><span class='icon icon-arrow-prev'></span></a><a href='#' class='jcarousel-control-next'><span class='icon icon-arrow-next'></span></a>");
  }

  $('.show-menu').click(function() {
      $(this).toggleClass('open').siblings('.collapse-mobile').slideToggle("open");
  });
});
