$(function() {
  $('.jcarousel').jcarousel({});

  $(".fancybox").fancybox();

/*
  $("#slider-range").slider({
    range: true,
    min: 500,
    max: 120000,
    values: [500, 120000],
    slide: function(event, ui) {
      $("#amount").val("$" +
        ui.values[0] +
        " - $" + ui.values[
          1]);
    }
  });
  $("#amount").val("$" + $(
      "#slider-range").slider(
      "values", 0) +
    " - $" + $("#slider-range").slider(
      "values", 1));
*/

  if ( $(window).width() > 480 ) {
    $('.grid:not(.row-product-list):not(.row-products-list)').masonry({
      columnWidth: '.grid-sizer',
      itemSelector: '.grid-item',
      percentPosition: true,
      gutter: 14,
      horizontalOrder: true
    });

    $('.grid:not(.row-product-list):not(.row-products-list)').imagesLoaded().progress(function() {
      $('.grid:not(.row-product-list):not(.row-products-list)').masonry('layout');
    });
  }

  $('.filter-mobile').click(function(e) {
    e.preventDefault();

    $(this).toggleClass('open');
    $('.filter-block').toggleClass('hidden-xs');
    $('.list-block').toggle();
  });

  if (!$('.table-custom').length) {
    $('#amount').change(function() {
      var $basketButton = $('.to-basket');
      var itemPrice = $basketButton.data('price');
      var $amount = $(this);
      var itemArea = $amount.data('area');
      var isAreaUnits = $('.unit-radio :checked').val() == 'area';
      var area = isAreaUnits ? $(this).val() : ($(this).val() * itemArea).toFixed(4);
      var roundedArea = area - (area % itemArea).toFixed(4);
      roundedArea = (area % itemArea).toFixed(4) != 0 ? roundedArea + itemArea : roundedArea;
      var totalPrice = (itemPrice * roundedArea / itemArea).toFixed(2);
      if (totalPrice.includes('.00')) {
        totalPrice = Number(totalPrice);
      }

      $amount.val(Number(isAreaUnits ? roundedArea.toFixed(4) : (roundedArea / itemArea).toFixed(4)));
      $('.to-basket').data('amount', (roundedArea / itemArea).toFixed(4))
        .closest('.section-product-item').find('.total-price .text-red').text(totalPrice);

    }).trigger('change');
  }

  $('.unit-radio input').change(function() {
    var isAreaUnits = $('.unit-radio :checked').val() == 'area';
    var $amount = $('.amount-basket');
    var itemArea = $amount.data('area');
    if (isAreaUnits) {
      $('.amount-basket').val(Number(($('.amount-basket').val() * itemArea).toFixed(4))).data('step', itemArea);
    } else {
      $('.amount-basket').val(Number(($('.amount-basket').val() / itemArea).toFixed(4))).data('step', 1);
    }
    $('#amount').trigger('change');
  });

  $('.show-modal').click(function() {
      $('.modal-product').slideToggle("open");
  });

  $('ul.nav-menu > li.dropdown').hover(function() {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
  }, function() {
    $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
  });

  var $headerGroup = $('#mobile-header');
  $headerGroup.on('show.bs.collapse','.collapse', function() {
    $headerGroup.find('.collapse.in').collapse('hide');
  });

  if ( $(window).width() < 800 ) {
    $('ul.nav-menu > li.dropdown > a').click(function(e) {
      var $this = $(this);
      $this.toggleClass('is-clicked').closest('li').siblings().find('a').removeClass('is-clicked');

      if ( $this.hasClass('is-clicked') ) {
        e.preventDefault();
      }
    });
  }

  $(document).on('yiiListViewUpdated argillaFilterUpdated', function() {
    $('.selectpicker').selectpicker();
  });

  // Galleries
  // ---------

  var TOSRUS_DEFAULTS = {
    buttons: {
      next: true,
      prev: true
    },

    keys: {
      prev: 37,
      next: 39,
      close: 27
    },

    wrapper: {
      onClick: 'close'
    }
  };

  // init tosrus static gallery
  $('.js-gallery').each(function() {
    $(this).find('.js-gallery-item').tosrus(TOSRUS_DEFAULTS);
  });

  $('.js-thumb-item').click(function(e) {
    e.preventDefault();

    var index = $(this).closest('li').index() + 1;
    $('.js-gallery-main ul li:eq(' + index + ') a').click();
  });

  // init tosrus single zoom
  $('.js-zoom').tosrus(TOSRUS_DEFAULTS);

  // Phone
  $('input[type="tel"]').mask('+7 (999) 999-99-99', {
    autoclear: false
  });

  $(document).on('click.scroll-to', '.js-scroll-to', function(e) {
    e.preventDefault();

    var $lnk = $(this);
    var $elemToScroll = $($lnk.attr('href'));
    var speed = $lnk.data('speed') || 150;
    var offset = $lnk.data('offset') || 0;

    $(window).scrollTo($elemToScroll, {duration: speed, offset: offset});
  });

  $('.js-scroll-to-tab').click(function(e) {
    e.preventDefault();
    var link = $(this).attr('href');
    $('#all-products .row-tabs li a[href="' + link + '"]').click();
    $('body').scrollTo($(link), {duration: 200, offset: -100});
  });

  $('.js-toggle-list').click(function(e) {
    e.preventDefault();
    $(this).closest('.js-toggle-container').toggleClass('expanded');
  });

  $('body').on('mousedown.spinner', '.spinner-down, .spinner-up', function() {
    var $spinnerControl = $(this),
        $input = $spinnerControl.siblings('.inp'),
        step = Number($input.data('step')),
        incTimeout, incInterval, decTimeout, decInterval;

    $spinnerControl
      .on('mouseup.spinner', clearAll)
      .on('mouseleave.spinner', $spinnerControl, clearAll);

    if ( $spinnerControl.hasClass('spinner-down') ) {
      decVal(); decTimeout = setTimeout(function() {
        decInterval = setInterval(decVal, 70);
      }, 300);
    }

    if ( $spinnerControl.hasClass('spinner-up') ) {
      incVal(); incTimeout = setTimeout(function() {
        incInterval = setInterval(incVal, 70);
      }, 300);
    }

    function incVal() {
      if( $.isMouseLoaderActive() )
        return;

      $input.val(Number((parseFloat($input.val()) + step).toFixed(4))).change();
    }

    function decVal() {
      if( $.isMouseLoaderActive() )
        return;

      if (parseFloat($spinnerControl.siblings('.inp').val()) > step) {
        $input.val(Number((parseFloat($input.val()) - step).toFixed(4))).change();
      }
    }

    function clearAll() {
      clearTimeout(decTimeout); clearInterval(decInterval);
      clearTimeout(incTimeout); clearInterval(incInterval);
    }
  });

  $('body').on('click', '.to-basket', function(e) {
    var $this = $(this),
        $img, $clone,
        $basket = $('#js-wrapper-basket-header');

    // if ( $this.closest('.product').length ) {
    //   if ( $this.closest('.product--details').length ) {
    //     $img = $this.closest('.product--details').find('.product--image img');
    //   } else {
    //     $img = $this.closest('.product').find('.product--image img');
    //   }
    // } else {
    //   $img = $('.product-card--main-image img:first');
    // }

    $img = $('.product-item .fancybox img');

    $clone = $img.clone().addClass('transition-to-basket');

    $clone.css({
      left: $img.offset().left,
      top: $img.offset().top,
      width: $img.width(),
      height: $img.height()
    }).appendTo( $('body') ).css({
      left: $basket.offset().left,
      top: $basket.offset().top,
      transform: 'scale(.2)',
      opacity: 0
    });

    setTimeout(function() {
      $clone.remove();
    }, 500);
  });

  $('.js-alphabet-filter, .js-country-filter').find('a').click(function(e) {
    e.preventDefault();

    var $this = $(this),
        param = $this.closest('.js-alphabet-filter').length ? 'letter' : 'country',
        value = $this.data( param );

    if ( $this.hasClass('active') ) {
      $('.js-alphabet-filter, .js-country-filter').find('a').removeClass('active');
      $('.list-brand-box').stop(true, true).fadeIn();
    } else {
      $('.js-alphabet-filter, .js-country-filter').find('a').removeClass('active');
      $this.addClass('active');
      $('.list-brand-box').hide();
      $('.list-brand-box[data-' + param + '="' + value + '"]').stop(true, true).fadeIn();
    }
  });

});

$(window).scroll(function(){
  if ($(this).scrollTop() > 290) {
    $('.step-one-block').addClass('fixed');
  } else {
    $('.step-one-block').removeClass('fixed');
  }
});


$(function() {
  $('.js-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    dots: true,
    pauseOnHover: false
    // appendArrows: $parent,
    // responsive: [
    //   {
    //     breakpoint: TABLET_WIDTH + 1,
    //     settings: {
    //       slidesToShow: 3,
    //     },
    //   },
    //   {
    //     breakpoint: SMALL_MOBILE_WIDTH + 1,
    //     settings: {
    //       slidesToShow: 2,
    //     },
    //   },
    // ]
  });
});
